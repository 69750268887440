<template>
	<div :class="classes">
		<header v-html="question"></header>
		<div class="audio-background">
			<canvas ref="canvas">
				Fallback...
			</canvas>
			<button type="button" aria-label="Start/Stop recording" @click="record">
				<IconMicrophone :width="32" :height="32" :strokewidth="2" />
			</button>
		</div>
		<div class="meta">
			<div class="time">
				Time: <span class="value">{{ limit }}</span>
			</div>
			<div v-if="block.meta.nrOfTries" class="tries">
				Tries: <span class="value">{{ tries }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '../../inc/bus';
	import IconMicrophone from '../icons/IconMicrophone';
	import uploadMixin from '../../mixins/uploadMixin';
	import {jsonToHtml} from '../../inc/text';
	import {DateTime} from 'luxon';
	import Store from '../../inc/store';

	export default {
		components: {
			IconMicrophone
		},
		mixins: [uploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				recording: false,
				recorder: null,
				audioChunks: [],
				recordingInteval: null,
				timeLimit: this.block.meta.timeLimit,
				triesRemaning: this.block.meta.nrOfTries,
				canvasCtx: null,
				bufferLength: null,
				dataArray: [],
				analyser: null
			};
		},
		computed: {
			limit() {
				const m = Math.floor(this.timeLimit / 60);
				let s = Math.floor(this.timeLimit % 60);

				if(s.toString().length < 2) {
					s = '0' + s;
				}

				return m + ':' + s;
			},
			tries() {
				return this.block.meta.nrOfTries ? this.triesRemaning + '/' + this.block.meta.nrOfTries : '';
			},
			classes() {
				return 'block-inner' + (this.recording ? ' recording' : '');
			},
			allowedFileTypes() {
				return ['audio/webm'];
			},
			question() {
				return jsonToHtml(this.block.content);
			}
		},
		watch: {
			answer() {
				if(this.answer && this.answer.files) {
					this.triesRemaning -= this.answer.files.length;
					this.triesRemaning = Math.max(0, this.triesRemaning);
				}
			}
		},
		methods: {
			setupAudioRecorder() {
				return navigator.mediaDevices.getUserMedia({
					audio: true
				}).then(stream => {
					this.recorder = new MediaRecorder(stream);
					this.recorder.ondataavailable = e => this.audioChunks.push(e.data);
					this.recorder.onstop = () => {
						clearInterval(this.recordingInteval);
						this.recording = false;
						requestAnimationFrame(this.clearCanvas);
						this.beginUpload();
					};
					this.recorder.onstart = () => {
						this.recording = true;
						this.setupCanvas(stream);

						if(this.block.meta.nrOfTries) {
							this.triesRemaning -= 1;
						}

						this.recordingInteval = setInterval(() => {
							this.timeLimit -= 1;

							if(this.timeLimit < 0) {
								this.recorder.stop();
							}
						}, 1000);
					};
				});
			},
			setupCanvas(stream) {
				if(this.$refs.canvas.getContext) {
					const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

					this.canvasCtx = this.$refs.canvas.getContext('2d');
					this.analyser = audioCtx.createAnalyser();

					const source = audioCtx.createMediaStreamSource(stream);

					source.connect(this.analyser);

					this.analyser.fftSize = 2048;
					this.bufferLength = this.analyser.frequencyBinCount;
					this.dataArray = new Uint8Array(this.bufferLength);

					this.draw();
				}
				else {
					// Do something with the fallback
				}
			},
			draw() {
				if(this.recording) {
					requestAnimationFrame(this.draw);
				}

				this.analyser.getByteTimeDomainData(this.dataArray);
				this.canvasCtx.lineWidth = 2;
				this.canvasCtx.strokeStyle = 'rgb(255, 255, 255)';
				this.canvasCtx.beginPath();

				const {width, height} = this.$refs.canvas.getBoundingClientRect();
				const sliceWidth = width / this.bufferLength;
				let x = 0;

				for(let i = 0; i < this.bufferLength; i++) {
					const v = this.dataArray[i] / 128.0;
					const y = v * height / 2;

					if(i === 0) {
						this.canvasCtx.moveTo(x, y);
					}
					else {
						this.canvasCtx.lineTo(x, y);
					}

					x += sliceWidth;
				}

				this.clearCanvas();
				this.canvasCtx.lineTo(width, height / 2);
				this.canvasCtx.stroke();
			},
			clearCanvas() {
				this.canvasCtx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
			},
			record() {
				if(this.recording) {
					this.recorder.stop();
				}
				else if(this.block.meta.nrOfTries && !this.triesRemaning) {
					Bus.emit('error', 'You have can\'t upload any more files to this question.');
				}
				else {
					this.setupAudioRecorder().then(() => {
						this.audioChunks = [];
						this.timeLimit = this.block.meta.timeLimit;

						this.recorder.start();
					}).catch(err => {
						Bus.emit('error', err.message);
					});
				}
			},
			beginUpload() {
				const filename = 'user-audio-' + Store.user.id + '-' + (DateTime.utc().toFormat('y-MM-dd-HH:mm:ss')) + '.webm';
				const file = new File(this.audioChunks, filename, {type: 'audio/webm'});

				this.filesChange([file]);
			}
		}
	};
</script>

<style lang="scss">
	.block.recaudio {
		.recording {
			.audio-background button {
				background-color: $color__red;
				padding: 20px;

				&:hover {
					background-color: darken($color__red, 5%);
				}

				svg {
					display: none;
				}

				&::before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					background-color: $color__white;
				}
			}
		}

		.audio-background {
			position: relative;
			height: 160px;
			background-color: $color__dark_blue;
			border-radius: $border_radius;


			canvas {
				width: 100%;
				height: 100%;
			}

			button {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 50%);
				border-radius: 50%;
				background-color: $color__light_blue;
				color: $color__white;
				padding: 14px;
				border: 3px solid $color__background;
				transition: background-color .2s ease;
				outline: none;

				&:hover {
					background-color: $color__blue;
				}

				svg {
					display: block;
				}
			}
		}

		.meta {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		.audio-background,
		.meta {
			max-width: 600px;
		}
	}
</style>